* {
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
/* Small */
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/* Medium */
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
/* Large */
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1500px) {
  .container {
    width: 70%;
  }
}
/* End Global Rules */

/* Start Landing Page */
.landing-page .header {
  min-height: 80px;
  display: flex;
}
.landing-page .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .landing-page .container {
    flex-direction: column;
    justify-content: center;
  }
}
.landing-page .header .logo {
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 600;
  font-size: 30px;
}
.landing-page .header .logo .logo-text {
  padding-left:5%;
}
@media (max-width: 767px) {
  .landing-page .header .logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.landing-page .header .links {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .landing-page .header .links {
    text-align: center;
    gap: 10px;
  }
}
.landing-page .header .links li {
  margin-left: 30px;
  border-radius: 10px;
  padding: 10px 20px;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 2;
  transition: .3s;
}
@media (max-width: 767px) {
  .landing-page .header .links li {
    margin-left: auto;
  }
}
.content .container {
  gap: 50px;
  min-height: calc(100vh - 80px);
}
@media (max-width: 767px) {
  .content .container {
    gap: 0;
    min-height: calc(100vh - 80px);
    justify-content: center;
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .landing-page .content .info-text {
    text-align: center;
    margin-bottom: 15px 
  }
}
.landing-page .content .info-text p {
  margin: 0;
  line-height: 1.6;
  font-size: 4.8vh;
  width: 50%;
  color: #000000cb;
}
@media (max-width:767px) {
  .landing-page .content .info-text p {
    width: 100%;
  }
}

.content2 .info {
  text-align: center;
  margin-bottom: 25px;
}

.content2 p {
  line-height: 1.6;
  font-size: 3.6vh;
  width: 100%;
  color: #000000a8;
}

.industries h3 {
  margin-bottom: 3%;
  margin-top: 2%;
}

.industries ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  list-style-type: none;
  margin-bottom: 10%;
}

.industries li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% / 3); /* Displaying 3 columns per row */
  position: relative;
  padding: 5px 0;
  margin: 0;
}

.industries li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  height: 2px; /* Line height */
  background-color: lightgrey; /* Line color */
}

.industries li span {
  margin-right: 2%;
  font-size: 3vh;
  color: #00000070;
}

.industries li p {
  margin: 0; /* Remove margin to reduce spacing */
}
@media (max-width: 992px) {
  .industries li {
    width: calc(100% / 2);
  }
}
@media (max-width: 767px) {
  .industries ul {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h3{
    text-align: center;
  }
  .industries li {
    width: 80%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px 0; /* Adjust padding for smaller screens */
  }
}


.landing-page::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10%;
  left: 10%;
  background-image: url('../Static/background.png'); /* Adjust the path if necessary */
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: right; /* Centers the image */
  background-repeat: no-repeat;
  z-index: -1;
  filter: blur(70px);
}

.landing-page .content .info-scroll {
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 5%;
}

@media (max-width: 767px) {
  .landing-page .content .info-scroll {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column; /* Stack items vertically */
  }

  .landing-page .content .info-scroll img {
    margin-bottom: 10px; /* Add some space between the image and text */
  }

  .landing-page .content .info-scroll p {
    padding-left: 0;
    text-align: center;
  }
}

.landing-page .content .info-scroll p {
  margin: 0;
  padding-left: 2%;
  line-height: 1.6;
  font-size: 20px;
  width: 50%;
  color: #000000;
}
.footer {
  display: flex;
  margin: 2%;
  align-items: center;
  justify-content: center;
}
.footer p {
  line-height: 1.6;
  font-size: 3.4vh;
  width: 100%;
  color: #000000a8;
  text-align: center;
}
.footer a {
  text-decoration: underline;
  color: #000000cc;
  font-weight: 550;
}
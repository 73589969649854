.solutions-section {
  margin-bottom: 40px; /* Add some space after solutions section */
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start; /* Distribute solutions evenly */
}
.solution-container p {
  line-height: 1.6;
  font-size: 2.5vh;
  width: 100%;
  color: #000000a8;
}

.solution-card {
  width: calc(33% - 20px); /* Set card width based on thirds */
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 20px;
  text-align: start;
}

.solution-card img {
  width: 12.5%; /* Set image to fill card width */
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .solution-card {
    width: calc(50% - 20px);
  }
}
@media (max-width: 767px) {
  .solution-container {
    justify-content: center;
  }
  .solution-card {
    width: 85%;
    text-align: center;
  }
  h2{
    text-align: center;
  }
}
